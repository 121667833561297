import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
// import { MainContext } from "../../context/MainContext";
import "./navmenu.scss";
import logo from '../../static/logo.svg'
import s1 from "../../images/s1.svg";
import s2 from "../../images/s2.svg";
import s3 from "../../images/s3.svg";
import s4 from "../../images/s4.svg";
import s5 from "../../images/s5.svg";
import s6 from "../../images/s6.svg";
import s7 from "../../images/s7.svg";
// import s1 from "../../images/s1.svg";
import c1 from "../../images/c1.svg";
import c2 from "../../images/c2.svg";
import c3 from "../../images/c3.svg";
import c4 from "../../images/c4.svg";

import p1 from "../../images/p1.svg";
import p2 from "../../images/p2.svg";
import p3 from "../../images/p3.svg";
import p4 from "../../images/p4.svg";
import p5 from "../../images/p5.svg";
import p6 from "../../images/p6.svg";
import p7 from "../../images/p7.svg";

import p8 from "../../images/p8.svg";

import a1 from "../../images/h1.svg";
import a2 from "../../images/h2.svg";
import a3 from "../../images/h3.svg";
import a4 from "../../images/h4.svg";
import a5 from "../../images/h5.svg";
import o1 from "../../images/h6.svg";
import o2 from "../../images/h7.svg";
import o3 from "../../images/h8.svg";
import o4 from "../../images/h9.svg";
import box from "../../images/box.svg";

import tokennaviconfull from "../../images/log.svg";

const navMenu = ["About", "Products", "Templates", "Pricing"];
const productMenu = [
  {
    id: 0,
    title: "MarketsOS",
    desc: "Suite of infrastructure products to build your fintech app.",
    icon: "marketos",
  },
  {
    id: 1,
    title: "MyCryptoBrand",
    desc: "Plug and play loyalty infused commerce solution for businesses.",
    icon: "mymcb",
  },
  {
    id: 2,
    title: "Metaverse App Store",
    desc: "List your business on the worlds first Metaverse App Store.",
    icon: "metaverse",
  },
];

const protocolMenu = [
  {
    id: 0,
    title: "ShareTokens",
    desc: "Representing a direct equity interest in a private company.",
    icon: "sharetokens",
  },
  {
    id: 1,
    title: "SafeTokens",
    desc: "Representing a CCPS agreement in a private company.",
    icon: "safetokens",
  },
  {
    id: 2,
    title: "CSOPTokens",
    desc: "Representing a CSOP agreement in a private company.",
    icon: "csoptokens",
  },
  {
    id: 3,
    title: "ESOPTokens",
    desc: "Representing a ESOP agreement in a private company.",
    icon: "esoptokens",
  },
  {
    id: 4,
    title: "BondTokens",
    desc: "Representing various corporate debt instruments.",
    icon: "bondtokens",
  },
  {
    id: 5,
    title: "EstateTokens",
    desc: "Representing ownership in a real estate asset.",
    icon: "estatetokens",
  },
];

const companyMenu = [
  {
    id: 0,
    title: "About Us",
    desc: "Learn about our mission, history and vision.",
  },
  {
    id: 1,
    title: "Team",
    desc: "Meet the founders and operational team that built the MarketsVerse.",
  },
  {
    id: 2,
    title: "Press",
    desc: "See everywhere MarketsVerse is mentioned in the media.",
  },
  {
    id: 3,
    title: "Contact",
    desc: "We would love to speak to you.",
  },
];

// const [selectedcontactquery,setselectedcontactquery]=useState("")
const Navmenu = () => {
  const [navHover, setnavHover] = useState("");
  const [companynav, setcompanynav] = useState("");
  const [selectNav, setselectNav] = useState("About");
  let navigate = useHistory();
  //   const { navHover, setnavHover, setcompanynav, setselectedcontactquery } =
  //     useContext(MainContext);
  const handleGo = (name) => {
    console.log(name, "hiiii");
    if (name === "About Us") {
      setcompanynav("company");
      navigate.push("/thecompany");
    } else if (name === "Team") {
      setcompanynav("founder");
      navigate.push("/thecompany/founder");
    } else if (name === "Contact") {
      setcompanynav("contactus");
      //  setselectedcontactquery("");
      navigate.push("/thecompany/contactus");
    } else if (name === "MarketsOS") {
      window.open("https://marketsverse.com/products/digital%20bank", "_blank");
    } else if (name === "MyCryptoBrand") {
      window.open("https://mycryptobrand.com", "_blank");
    } else if (name === "Metaverse App Store") {
      window.open("https://metaverseapp.store", "_blank");
    }
  };

  const [openactions, setOpenactions] = useState(true);
  const [userwidth, setuserwidth] = useState(0);
  // useEffect(() => {
  //   window.addEventListener("scroll", isSticky);
  //   return () => {
  //     window.removeEventListener("scroll", isSticky);
  //   };
  // });

  /* Method that will fix header after a specific scrollable */
  const isSticky = (e) => {
    const scrollTop = window.scrollY;
    if (scrollTop >= 30) {
      setOpenactions(true);
    } else {
      setOpenactions(false);
    }
  };
  return (
    <>
      <div className="menushow">
        <div
          className="dashboard-nav-main"
      
        >
          <div
            className="dashboard-navbar"
         
          >
            <img className="nav-logo" src={logo} alt="" />
            {/* <div className="nav-left"></div> */}
            <div className="nav-right">
              <>
                {navMenu.map((each, i) => (
                  <div
                    className="menu-titles"
                    key={i}
                    onMouseEnter={() => setnavHover(each)}
                    // onMouseLeave={() => setnavHover("")}
                    style={{
                      color:each === "About" && "#4B9DDC"
                    }}
                  >
                    {each}
         
                  </div>
                ))}
              </>
              <div className="nav-btn-apps">Apps</div>
              <div className="nav-btn">Get Started</div>
            </div>
            {/* <div className="label-dec">
              <div className="nav-logoin">Login</div>
            
            </div> */}
          </div>
        </div>

        <div className="nav-menu-container">
          <div
            className="big-box3"
            onMouseEnter={() => setnavHover("producctsa")}
            onMouseLeave={() => setnavHover("")}
            style={{ display: navHover === "productsa" ? "" : "none" }}
          >
            <div className="menus-left">
              <div className="menu-title">Data Products</div>
              <section
                className="menus"
                //   onClick={() =>
                //     window.open("https://indianinvestor.com", "_blank")
                //   }
              >
                <div className="menu-img-box">
                  <img className="menu-img" src={p1} alt="" />
                </div>
                <div>
                  <div className="menu-title">AI Index</div>
                  <div className="menu-desc">
                    Compare automated trading products across companies.
                  </div>
                </div>
              </section>
              <section
                className="menus"
                //   onClick={() =>
                //     window.open("https://indianinvestor.com", "_blank")
                //   }
              >
                <div className="menu-img-box">
                  <img className="menu-img" src={p2} alt="" />
                </div>
                <div>
                  <div className="menu-title">DGAcademy</div>
                  <div className="menu-desc">
                    Learn how to incorporate AI in your investing strategies.
                  </div>
                </div>
              </section>

              <div className="menu-title" style={{ marginTop: "2rem" }}>
                Exchange Products
              </div>
              <section
                className="menus"
                //   onClick={() =>
                //     window.open("https://indianinvestor.com", "_blank")
                //   }
              >
                <div className="menu-img-box">
                  <img className="menu-img" src={p3} alt="" />
                </div>
                <div>
                  <div className="menu-title">Fiat To Crypto</div>
                  <div className="menu-desc">
                    Buy and sell crypto with CAD. More currencies to come.
                  </div>
                </div>
              </section>
              <section
                className="menus"
                //   onClick={() =>
                //     window.open("https://indianinvestor.com", "_blank")
                //   }
              >
                <div className="menu-img-box">
                  <img className="menu-img" src={p4} alt="" />
                </div>
                <div>
                  <div className="menu-title">Crypto To Crypto</div>
                  <div className="menu-desc">
                    Seamlessly swap between 60+ cryptocurrencies.
                  </div>
                </div>
              </section>
            </div>
            <div className="menus-right">
              <div className="menu-title">Automated Products</div>
              <section className="menus">
                <div className="menu-img-box">
                  <img className="menu-img" src={p5} alt="" />
                </div>
                <div>
                  <div className="menu-title">StakingVaults</div>
                  <div className="menu-desc">
                    Earn daily rewards for holding BTC, ETH, and USDT in your
                    wallet.
                  </div>
                </div>
              </section>
              <section className="menus">
                <div className="menu-img-box">
                  <img className="menu-img" src={p6} alt="" />
                </div>
                <div>
                  <div className="menu-title">OTCBots</div>
                  <div className="menu-desc">
                    Setup a recurring purchase of your favourite coin.
                  </div>
                </div>
              </section>
              <section className="menus">
                <div className="menu-img-box">
                  <img className="menu-img" src={p7} alt="" />
                </div>
                <div>
                  <div className="menu-title">DGFX</div>
                  <div className="menu-desc">
                    Our proprietary automated trading strategy for forex
                    markets.
                  </div>
                </div>
              </section>
              <section className="menus">
                <div className="menu-img-box">
                  <img className="menu-img" src={p8} alt="" />
                </div>
                <div>
                  <div className="menu-title">DGPROF</div>
                  <div className="menu-desc">
                    Our proprietary automated trading strategy for crypto
                    markets.
                  </div>
                </div>
              </section>
            </div>
          </div>
          {/* <div
          className="big-box1"
          onMouseEnter={() => setnavHover("products")}
          onMouseLeave={() => setnavHover("")}
          style={{ display: navHover === "products" ? "" : "none" }}
        >
          <div className="menu-title">Our Product Lines:</div>
          <div>
            {productMenu.map((each, i) => (
              <div
                className="each-menu"
                key={i}
                onClick={() => handleGo(each.title)}
              >
                <div className="menu-img-box">
                  <img className="menu-img" src={each.icon} alt="" />
                </div>
                <div>
                  <div className="menu-title">{each.title}</div>
                  <div className="menu-desc">{each.desc}</div>
                </div>
              </div>
            ))}
          </div>
        </div> */}
          {/* <div
          className="big-box2"
          onMouseEnter={() => setnavHover("protocols")}
          onMouseLeave={() => setnavHover("")}
          style={{ display: navHover === "protocols" ? "" : "none" }}
        >
          <div className="menu-title">Our Protocols</div>
          <div>
            {protocolMenu.map((each, i) => (
              <div className="each-menu" key={i}>
                <div className="menu-img-box">
                  <img className="menu-img" src={each.icon} alt="" />
                </div>
                <div>
                  <div className="menu-title">{each.title}</div>
                  <div className="menu-desc">{each.desc}</div>
                </div>
              </div>
            ))}
          </div>
        </div> */}
          <div
            className="big-box3"
            onMouseEnter={() => setnavHover("Productns")}
            onMouseLeave={() => setnavHover("")}
            style={{
              display: navHover === "Productls" ? "" : "none",
              top: openactions ? "93px" : "80px",
            }}
          >
            <div className="menus-left">
              <div className="menu-title">Brandable Applications</div>
              <section className="menus">
                <div className="menu-img-box">
                  <img className="menu-img" src={a1} alt="" />
                </div>
                <div>
                  <div className="menu-title">OTCDesks</div>
                  <div className="menu-desc">
                    Launch your own fiat to crypto exchange.
                  </div>
                </div>
              </section>
              <section className="menus">
                <div className="menu-img-box">
                  <img className="menu-img" src={a2} alt="" />
                </div>
                <div>
                  <div className="menu-title">Terminals</div>
                  <div className="menu-desc">
                    Launch your own crypto to crypto exchange.
                  </div>
                </div>
              </section>
              <section className="menus">
                <div className="menu-img-box">
                  <img className="menu-img" src={a3} alt="" />
                </div>
                <div>
                  <div className="menu-title">Tellers</div>
                  <div className="menu-desc">
                    Create your own crypto enabled remittence platform.
                  </div>
                </div>
              </section>
              <section className="menus">
                <div className="menu-img-box">
                  <img className="menu-img" src={a4} alt="" />
                </div>
                <div>
                  <div className="menu-title">TokenSwap</div>
                  <div className="menu-desc">
                    Launch your own decentralized crypto to crypto exchange.
                  </div>
                </div>
              </section>
              <section className="menus">
                <div className="menu-img-box">
                  <img className="menu-img" src={a5} alt="" />
                </div>
                <div>
                  <div className="menu-title">TokenOptions</div>
                  <div className="menu-desc">
                    Launch your own crypto derivatives exchange.
                  </div>
                </div>
              </section>
            </div>
            <div className="menus-right">
              <div className="menu-title">Additional Services</div>
              <section className="menus">
                <div className="menu-img-box">
                  <img className="menu-img" src={o1} alt="" />
                </div>
                <div>
                  <div className="menu-title">Safe Storage</div>
                  <div className="menu-desc">
                    Segregated custody management solution for your business.
                  </div>
                </div>
              </section>
              <section className="menus">
                <div className="menu-img-box">
                  <img className="menu-img" src={o2} alt="" />
                </div>
                <div>
                  <div className="menu-title">CryptocurrencyShield</div>
                  <div className="menu-desc">
                    Procure insurance for the digital assets on your exchange.
                  </div>
                </div>
              </section>
              <section className="menus">
                <div className="menu-img-box">
                  <img className="menu-img" src={o3} alt="" />
                </div>
                <div>
                  <div className="menu-title">OTCBots</div>
                  <div className="menu-desc">
                    Create automated fiat to crypto exchange products.
                  </div>
                </div>
              </section>
              <section className="menus">
                <div className="menu-img-box">
                  <img className="menu-img" src={o4} alt="" />
                </div>
                <div>
                  <div className="menu-title">TradeRouting</div>
                  <div className="menu-desc">
                    Connect your order book to any exchange in the world.
                  </div>
                </div>
              </section>
            </div>
          </div>
          {/* c */}

          <div
            className="big-box3"
            onMouseEnter={() => setnavHover("Communities")}
            onMouseLeave={() => setnavHover("")}
            style={{ display: navHover === "Communities" ? "" : "none" }}
          >
            <div className="menus-left">
              <div className="menu-title">Tools</div>
              <section className="menus">
                <div className="menu-img-box">
                  <img className="menu-img" src={c1} alt="" />
                </div>
                <div>
                  <div className="menu-title">Branding Materials</div>
                  <div className="menu-desc">
                    Access all the presentations and sales documents you need.
                  </div>
                </div>
              </section>
              <section className="menus">
                <div className="menu-img-box">
                  <img className="menu-img" src={c2} alt="" />
                </div>
                <div>
                  <div className="menu-title">Weekly Webinars</div>
                  <div className="menu-desc">
                    Register for our weekly opportunity and training calls.
                  </div>
                </div>
              </section>
              <section className="menus">
                <div className="menu-img-box">
                  <img className="menu-img" src={c3} alt="" />
                </div>
                <div>
                  <div className="menu-title">Rankings & Rewards</div>
                  <div className="menu-desc">
                    See how we reward the movers and shakers in AiProWallet.
                  </div>
                </div>
              </section>
              <section className="menus">
                <div className="menu-img-box">
                  <img className="menu-img" src={c4} alt="" />
                </div>
                <div>
                  <div className="menu-title">Training Center</div>
                  <div className="menu-desc">
                    Browse through all the training videos and FAQ’s.
                  </div>
                </div>
              </section>
            </div>
            <div className="menus-right">
              <div className="menu-title">Social</div>

              <div className="socialmedia">
                <div className="box">
                  <img src={s1} />
                </div>
                <div className="box">
                  <img src={s2} />
                </div>
                <div className="box">
                  <img src={s3} />
                </div>
                <div className="box">
                  <img src={s4} />
                </div>
              </div>

              <div className="socialmedia">
                <div className="box">
                  <img src={s5} />
                </div>

                <div className="box">
                  <img src={s6} />
                </div>
                <div className="box">
                  <img src={s7} />
                </div>
              </div>
            </div>
          </div>

          {/*  */}
          <div
            className="big-box3"
            onMouseEnter={() => setnavHover("thecomapny")}
            onMouseLeave={() => setnavHover("")}
            style={{ display: navHover === "thecomapny" ? "" : "none" }}
          >
            <div className="menus-left">
              <div className="menu-title">About Us</div>
              <section className="menus">
                <div>
                  <div className="menu-title">Mission</div>
                  <div className="menu-desc">
                    Learn about our mission, history and vision.
                  </div>
                </div>
              </section>
              <section className="menus">
                <div>
                  <div className="menu-title">Press</div>
                  <div className="menu-desc">
                    See everywhere MarketsVerse is mentioned in the media.
                  </div>
                </div>
              </section>
              <section className="menus">
                <div>
                  <div className="menu-title">Contact</div>
                  <div className="menu-desc">
                    We would love to speak to you.
                  </div>
                </div>
              </section>
              <section className="menus">
                <div>
                  <div className="menu-title">Policies</div>
                  <div className="menu-desc">
                    Review all our legal and operating agreements.
                  </div>
                </div>
              </section>
            </div>
            <div className="menus-right">
              <div className="menu-title">Team</div>
              <section className="menus">
                <div>
                  <div className="menu-title">Founders</div>
                  <div className="menu-desc">
                    Meet the founding team that built the AiProWallet.
                  </div>
                </div>
              </section>
              <section className="menus">
                <div>
                  <div className="menu-title">Super Affiliates</div>
                  <div className="menu-desc">
                    Learn from an elite group of Super Affiliates who have
                    scaled their businesses.
                  </div>
                </div>
              </section>
              <section className="menus">
                <div>
                  <div className="menu-title">Influencers</div>
                  <div className="menu-desc">
                    Checkout the hottest online influencers promoting
                    AiProWallet
                  </div>
                </div>
              </section>
              <section className="menus">
                <div>
                  <div className="menu-title">Partners</div>
                  <div className="menu-desc">
                    Discover our amazing exchange partners in each country.
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Navmenu;
