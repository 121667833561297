
import React, { Component } from 'react'
import {BrowserRouter as Router,Switch,Route} from 'react-router-dom'
import Homepage from '../src/pages/LandingPage';

import "./App.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
export default class App extends Component 
{
  render() {
    return (
      <div>
        {/* <Homepage /> */}
      <Router>

        <Switch>
      
          <Route exact  path="/" component={Homepage}/>
          
          {/* <Route  path="/Compare/:code" component={CompareHacker}/>
          <Route  path="/Exchange/Bibox" component={Bibox}/>
          <Route  path="/Exchange/Binance" component={Binance}/>
          <Route  path="/Exchange/Bittrex" component={Bittrex }/>
          <Route  path="/Exchange/Bitfinex" component={Bitfinex}/>
          <Route  path="/Exchange/Coss" component={Coss}/>
          <Route  path="/Exchange/Kraken" component={Kraken}/>
          <Route  path="/Exchange/Kucoin" component={Kucoin}/>
          <Route  path="/Exchange/Poloniex" component={Poloniex}/>
          <Route  path="/Exchange/Upbit" component={Upbit}/>
          <Route  path="/Compare" component={Compare}/> */}
          </Switch>
     
        </Router>
        </div>
    )
  }
}

