import React from "react";
import "./LandingPage.scss";
import logo from "../images/logos.svg";
import air from "../images/airs.svg";
import h from "../images/h.svg";
import socialy from "../images/socialy.svg";
import Fotter from "../components/Footer";
import Startbussiness from "../components/startBussiness"
import CardSection from '../components/cardsSection'
import Price from '../components/Price'
import Nav from '../components/navMenus/navmenu'
import Section from '../components/Sectionone'
export default function index() {
  return (
    <>
<Nav/>
<Section/>
    {/* <Price/>
    <CardSection/> */}
    {/* <Startbussiness/>
      <Fotter /> */}
    </>
  );
}
