import React, { useEffect, useState } from "react";
import TextTransition, { presets } from "react-text-transition";
import a1 from "../../static/a1.svg";
import a2 from "../../static/a2.svg";
import "./Section.scss";
import axios from 'axios'
export default function Index() {
  const TEXTS = [
    "Blog",
    "Newsletter",
    "Followers",
    "Customers",
    "Viewers",
    "Courses",
  ];
  const [index, setIndex] = React.useState(0);
  const [info, setinfo] = useState([]);
  React.useEffect(() => {
    const intervalId = setInterval(
      () => setIndex((index) => index + 1),
      3000 // every 3 seconds
    );
    return () => clearTimeout(intervalId);
  }, []);
  useEffect(() => {
    handleCreateAccount();
  }, []);

  const handleCreateAccount = () => {
    axios
      .get(`https://publications.apimachine.com/publication/`)
      .then(({ data }) => {
        if (data.status) {
          setinfo(data?.data);
        }
      });
  };

  return (
    <div className="sectionOne">
      <div>
        <div className="flex-section">
          <h1>Earn More From Your</h1>
          <h2>
            <TextTransition springConfig={presets.wobbly}>
              {TEXTS[index % TEXTS.length]}
            </TextTransition>
          </h2>
        </div>
        <h1
          style={{
            marginBottom: "0px",
          }}
        >
          With Your Own Publication
        </h1>
      </div>
      <p>
        Combine interactive content, communities, and features to create your
        own publication app. Unlock engagement and recurring revenue your
        website can’t.
      </p>
      <div className="labelsectiom">
        <label className="white">For Writers</label>
        <label className="color">For Publishers</label>
      </div>
      <div className="cardbottom">
        {info?.map((item) => {
          return (
            <>
            <div className="sectionitem">
            <div className="items">
              <img src={item?.profile_pic} />
            </div>
            <h5>{item?.name}</h5>
            </div>
            </>
          );
        })}

      </div>
    </div>
  );
}
